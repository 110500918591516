<template>
  <Head :title="title"/>

  <div class="flex flex-col min-h-screen">
    <PageHeader v-if="!raw"/>

    <main class="flex-grow" :class="!raw ? 'sm:py-6 lg:py-8' : ''">
      <div :class="!raw ? 'max-w-7xl mx-auto sm:px-6 lg:px-8' : '' ">
        <div :class="!raw ? 'px-4 sm:px-0' : ''">
          <NotificationBox/>

          <slot/>
        </div>
      </div>
    </main>

    <PageFooter v-if="!raw"/>
  </div>
</template>

<script setup>
import PageFooter from "./PageFooter.vue";
import PageHeader from "./PageHeader.vue";
import NotificationBox from "../basic/NotificationBox.vue";
import {Head} from '@inertiajs/vue3';
import * as Sentry from "@sentry/browser";
import {computed, onMounted} from "vue";
import {usePage} from '@inertiajs/vue3'

const page = usePage()
const user = computed(() => page.props.auth.user)

defineProps({
  title: String,
  raw: {type: Boolean, default: false}
});

onMounted(() => {
  Sentry.setUser({id: user.value?.id ?? null, group: user.value?.group_id ?? null});
});
</script>

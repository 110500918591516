<template>
  <div v-if="$page.props.flash.success" class="mb-8">
    <div class="rounded-lg shadow-md p-6 bg-green-200 text-green-900">
      <div class="flex items-center">
        {{ $page.props.flash.success }}
      </div>
    </div>
  </div>

  <div v-if="$page.props.flash.error" class="mb-8">
    <div class="rounded-lg shadow-md p-6 bg-red-200 text-red-900">
      <div class="flex items-center">
        {{ $page.props.flash.error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close: function close(ev) {

    }
  }
};
</script>

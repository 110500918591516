<template>
    <footer aria-labelledby="footer-heading" class="bg-white mt-auto">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3
                                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
                            >
                                Navigation
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li
                                    v-for="item in navigation.solutions"
                                    :key="item.name"
                                >
                                    <a
                                        :href="item.href"
                                        class="text-base text-gray-500 hover:text-gray-900"
                                    >
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3
                                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
                            >
                                Informationscenter
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li
                                    v-for="item in navigation.support"
                                    :key="item.name"
                                >
                                    <a
                                        :href="item.href"
                                        class="text-base text-gray-500 hover:text-gray-900"
                                    >
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3
                                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
                            >
                                Unternehmen
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li
                                    v-for="item in navigation.company"
                                    :key="item.name"
                                >
                                    <a
                                        :href="item.href"
                                        class="text-base text-gray-500 hover:text-gray-900"
                                    >
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-8 xl:mt-0 mb-4">
                    <div>
                        <img
                            v-for="item in navigation.logos"
                            :alt="item.title"
                            :title="item.title"
                            :src="item.url"
                            class="float-left ml-4 max-h-24"
                        />
                    </div>
                </div>
            </div>
            <div
                class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between"
            >
                <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                    &copy; Dr. Becker Klinikgruppe
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            navigation: {
                solutions: [
                    { name: "Startseite", href: route("home") },
                    { name: "Angebote", href: route("courses.index") },
                    { name: "Mein Bereich", href: route("my_area.index") },
                ],
                support: [
                    // {name: 'FAQ', href: '#'},
                    { name: "Kontakt", href: route("kontakt.index") },
                ],
                company: [
                    { name: "AGB", href: route("general_terms") },
                    { name: "Impressum", href: route("legal_notice") },
                    { name: "Datenschutz", href: route("data_protection") },
                ],
                logos: [
                    // {title: 'Registrierung beruflich Pflegender', url: '/img/logo-register-care.png'},
                    // {title: 'Gütesiegel TÜV Darmstadt', url: '/img/logo-tu.png'},
                    // {title: 'Klinik Award 2015', url: '/img/logo-award-2015.png'}
                ],
            },
        };
    },
};
</script>
